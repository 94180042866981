













import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import {
  INTERVIEW_SEARCHED_JOB,
  RECRUITER_INTERVIEW_SEARCH_JOB_LOADING
} from "@/store/modules/recruiter/constants";

import { SearchJobObject } from "@/interfaces/shared/jobs/auto_complete_job_search";
import AutoCompleteCustomJobSearch from "@/components/shared/jobs/AutoCompleteCustomJobSearch.vue";

export default Vue.extend({
  name: "InterviewListingHeader",
  components: { AutoCompleteCustomJobSearch },
  computed: {
    ...mapGetters("recruiter", {
      get_searched_job: INTERVIEW_SEARCHED_JOB
    })
  },
  methods: {
    ...mapMutations("recruiter", {
      set_searched_job: INTERVIEW_SEARCHED_JOB,
      set_search_job_loading: RECRUITER_INTERVIEW_SEARCH_JOB_LOADING
    }),
    async search_job_title(value: SearchJobObject | null) {
      value
        ? this.set_search_job_loading(true)
        : this.set_search_job_loading(false);
      this.set_searched_job(value);
    }
  }
});
