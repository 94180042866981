import { Jobs, JobsWithSkillsGroup } from "@/interfaces/data_objects/jobs";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { SearchJobObject } from "@/interfaces/shared/jobs/auto_complete_job_search";
import { Interview } from "@/interfaces/responses/interviews/interviews";
import { AssessmentsListingResponse } from "@/interfaces/responses/assessments/assessments";
import { SocialLinks } from "../onboarding/interfaces";
import { ZappyhireCandidate } from "@/interfaces/data_objects/user";
import { IAppAssessment, IOnetResults } from "@/interfaces";

export interface RecruiterState {
  loading: boolean;
  jobs_posted_loading: boolean;
  poke_candidate_loading: boolean;
  view_candidates_dynamically: ViewCandidatesDynamically;
  add_standardised_job: JobsWithSkillsGroup | null;
  edited_Standardised_job: boolean;
  jobs_posted: {
    pagination: number;
    total: number;
    results: JobsWithCandidates[];
    date_filter: string[];
    searched_job_title: SearchAutocompleteJobPayload | null;
  };
  top_jobs_posted: {
    total: number;
    results: JobsWithCandidates[];
  };
  recommend_job_profiles: Jobs[];
  // When a recruiter wants to view a matching candidate, this payload will contain job_id & cv_id
  // Job id and cv id is required to view candidate profile
  candidate_view_payload: CandidateViewPayload | null;
  all_candidates: AllCandidates;
  post_job_limit_error: string;
  interviews: Interviews;
  view_candidates_pagination: ViewCandidatesPagination;
  assessment_listing: AssessmentListing;
  knowledge_base: KnowledgeBasePayload;
  searched_text: string;
}

export interface AssessmentListing {
  fetch_loading: boolean;
  pagination: number;
  selected_dates: string[];
  data: AssessmentsListingResponse.Root;
}
export interface ViewCandidatesPagination {
  pagination: number;
  searched_skill_filter: string;
}
export interface ViewCandidatesDynamically {
  active: boolean;
  loading: boolean;
  job_id: number | null;
  job_title: string;
  skill: string;
  user_ids: [];
}
export interface JobsWithCandidates extends Jobs {
  matching_candidates: SearchedCandidate[];
  candidates_loading: boolean;
}

export interface PostJobPayload {
  job_title: string;
  job_description: string;
  job_industry: string;
  job_type: string;
  job_experience: string;
  skills_list: string;
  is_standardize: boolean;
  edited: boolean;
}

export interface GetJobsPostedPayload {
  page?: number;
  limit_per_page?: number;
  dashboard?: boolean;
  is_standardize?: boolean;
  job_title?: string;
  date_filter?: string[];
}

/**
 * Interface defining the payload structure for requesting a short URL for a job.
 *
 * This interface is used to type-check the data sent to the API when requesting
 * a short URL for a specific job. The properties are optional to allow flexibility
 * in the payload composition, depending on the requirements of the API endpoint.
 *
 * @property {number} [job_id] - Optional job identifier; required if `job_url` is not provided.
 * @property {string} [job_url] - Optional string representing the full URL of the job; required if `job_id` is not provided.
 *
 * Note: At least one of the properties should be provided for the API to process the request and generate a short URL.
 * The consumer of this interface should ensure that the payload adheres to the API's expectations.
 */
export interface GetJobShortUrlPayload {
  job_id?: number;
  job_url?: string;
}

export interface SetJobsMutationPayload {
  total: number;
  results: JobsWithCandidates[];
}

export interface MatchingCandidatesByIdPayload {
  job_id: number;
  n?: number;
  dashboard?: boolean;
  skill_name?: string;
  page?: number;
  limit_per_page?: number;
  user_ids?: number[];
}

export interface UpdateMatchingCandidatePayload {
  job_id: number;
  candidates: null | Candidates.Candidates[];
}

export interface UpdateJobPayload {
  job_id: number;
  job_title: string;
  job_description: string;
  job_sector: string;
  job_experience: string;
  job_employment_type: string;
  job_skills_list: string;
}
export interface CandidateViewPayload {
  job_id: number;
  cv_id: number;
  upload_response_id: string;
}

export interface MembersJobsStatistics {
  filter: string;
  start_date: string;
  end_date: string;
}

export interface UpdateFavoriteCandidatePayload {
  is_favorite: boolean;
  candidate_id: number;
  job_id: number;
}

export interface AllCandidates {
  total: number;
  results: Candidates.Candidates[];
  filtered_count: number;
}

// Interface for poke candidate request payload
export interface PokeCandidatePayload {
  job_id: number; // Job jid
  candidate_id: number; // User pk
  type?: string;
}

// Interface for saving updated job description
export interface SaveUpdatedJobDescriptionPayload {
  job_id: number;
  description: string;
  sector: string;
}

// For start interview API POST call payload
export interface StartInterviewPayload {
  candidate_id: number;
  interview_id: number;
  interview_type?: string;
}

// For resume interview API POST call payload
export interface ResumeInterviewPayload {
  interview_id: number;
  answer?: string;
  interview_type?: string;
}
// For storing interview variables
export interface Interviews {
  search_job_loading: boolean;
  searched_job: SearchJobObject | null;
  pagination: number;
  interview_listing: Map<number, InterviewListingResult>;
  selected_candidate: InterviewListingCandidates[];
}

export interface InterviewListing {
  [id: string]: InterviewListingResult;
}

export interface InterviewListingResult {
  id: number;
  title: number;
  date: string;
  candidates: InterviewListingCandidates[];
  zappy_job_id?: number;
}

export interface InterviewListingCandidates {
  avatar_uri: string;
  first_name: string;
  interview_status: string;
  last_name: string;
  id: number;
  class: string;
  readable_status: string;
  interview_by: number;
  interview_id: number;
  candidate_by: number;
  job_id: number;
  content?: string;
  candidate_id?: number;
  zappy_job_id?: number;
}
export interface InterviewListingPayload {
  page?: number;
  limit?: number;
  interviewed_by?: number;
  job_id?: number;
  candidate_id?: number;
  interview_id?: number;
}

export interface UpdateInterviewStatusPayload {
  interview_id: number;
  action: Interview.Status;
  recording?: File;
  filename?: string;
}

export interface InterviewHistoryData {
  [key: number]: string;
}

export interface IndividualInterviewHistory {
  candidate_interview_history: string;
  name: string;
  avatar?: string;
  candidate_id?: number;
  zappy_job_id?: number;
  job_id: number;
}

export interface InterviewReport {
  [key: string]: InterviewReportDetails;
}
export interface InterviewReportDetails {
  type: string;
  value: ReportDetail[];
}
export interface ReportDetail {
  rating?: number;
  ranking?: number;
  reason: string;
  color: string;
  bg_color: string;
  icon: string;
  key: string;
}
export interface FetchAssessmentListingPayload {
  page: number;
  limit: number;
  selected_searched_user: number[];
  date_picker_field: string[] | null;
}

export interface SearchAutocompleteJobPayload {
  job_id: number;
  text: string;
}

export interface SearchedCandidate {
  job_id?: number;
  score?: number;
  candidate_id: number;
  upload_response_id: string;
  upload_response: {
    profile?: SearchedCandidateProfile;
  };
  avatar_uri: string;
  is_favorite?: boolean;
  social_links: SocialLinks[];
  availability: string[];
  status: string;
}
export interface SearchedCandidateProfile {
  personal_details: {
    name: {
      first_name: string;
      middle_name: string;
      last_name: string;
    };
  };
  work_history_detailed?: {
    [key: string]: {
      job_title: string;
    };
  };
  total_experience?: string;
}

export interface SearchCandidatesPayload {
  job_ids?: number[];
  n?: number;
  dashboard?: boolean;
  skill_name?: string;
  page?: number;
  limit_per_page?: number;
  user_ids?: number[];
}

export interface SearchCandidatesAccumulator {
  [key: number]: SearchedCandidate[];
}

export interface UpdateMatchingCandiadteMutationPayload {
  candidates_job_based: SearchCandidatesAccumulator;
}

export interface ZappyhireCreateCandidatePayload {
  job_id: number;
  payload: ZappyhireCandidate;
  candidate_id: number;
}

export interface ZapphirePostJobResponse {
  status: number;
  errors: string;
  results: {
    job_id?: number;
  };
}

export interface ZapphireCreateCandidateResponse {
  results?: {
    candidate_id?: number;
  };
}

export interface UpdateZappyInterviewListingObjectPayload {
  job_id: number;
  zappy_job_id: number;
}

export interface RatingFeedback {
  Rating?: number;
  Reason?: string;
  rating?: number;
  reason?: string;
  Ranking?: number;
  ranking?: number;
}

export interface KnowledgeBasePayload {
  limit: number;
  page: number;
  total: number;
  data: KnowledgeData[];
  info: KnowledgeInfo;
}

export interface KnowledgeData {
  created_at: string;
  filename: string;
  location: string;
  uploaded_by: KnowledgeBaseUploader;
  uri: string;
}

interface KnowledgeBaseUploader {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
}

interface KnowledgeInfo {
  flag: boolean;
  msg: string;
}

export interface AssessmentResultApiPayload {
  assessment_id: number;
  user_id: number;
  total_questions?: number;
  current_question?: number;
  status: IAppAssessment.Result;
  result?: JSON | IOnetResults.Root;
  urls?: JSON;
}

export interface GetAssessmentResultApiPayload {
  assessment_id: number;
  user_id?: number;
}

export interface InterviewActionPayload {
  candidate_id: number;
  Interview_id: number;
  action: InterviewActions;
}

export enum InterviewActions {
  REMOVED = "REMOVED",
  REDO = "REDO"
}
