var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"base-card all-interviewers-container"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('p',{staticClass:"base-section-title"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.interview.listing.interview-report-title"))+" ")]),_c('v-btn',{staticClass:"dark",attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('close_popup')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"all-interviewers my-2 my-md-5"},_vm._l((_vm.candidates),function(candidate){return _c('div',{key:candidate.id + _vm.generate_random_key(),staticClass:"candidate-container"},[_c('div',{staticClass:"\n          d-flex\n          flex-column flex-md-row\n          align-md-center\n          justify-space-between\n          py-8\n          candidate-box\n        "},[_c('div',{staticClass:"d-flex align-center",style:(_vm.$vuetify.breakpoint.mdAndDown ? 'width: 100%' : 'width: 50%')},[_c('v-avatar',{staticClass:"avatar",attrs:{"size":_vm.$vuetify.breakpoint.mdAndDown ? 70 : 80,"color":"primary"}},[(candidate.avatar_uri)?_c('v-img',{attrs:{"src":candidate.avatar_uri}}):_c('span',{staticClass:"base-section-title white--text"},[_vm._v(" "+_vm._s(_vm.name_initials(candidate))+" ")])],1),_c('p',{staticClass:"mb-0 ms-3 base-section-title user-name"},[_vm._v(" "+_vm._s(candidate.first_name)+" "+_vm._s(candidate.last_name)+" ")])],1),_c('div',{staticClass:"my-5 my-md-0",style:(_vm.$vuetify.breakpoint.mdAndDown ? 'width: 100%' : 'width: 25%')},[_c('v-chip',{class:candidate.class},[_vm._v(" "+_vm._s(candidate.readable_status)+" ")])],1),_c('div',{staticClass:"action-buttons",style:(_vm.$vuetify.breakpoint.mdAndDown ? 'width: 100%' : 'width: 25%')},[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn download_btn",attrs:{"icon":"","color":"primary","disabled":candidate.interview_status !== _vm.Interview.Status.Finished},on:{"click":function($event){return _vm.view_candidate_interview_report(candidate)}}},'v-btn',attrs,false),on),[_c('inline-svg',{attrs:{"width":"21","height":"21","src":_vm.document_download,"transformSource":function (icon) { return _vm.transform_icon(icon, '#1c53f4'); }}})],1)]}}],null,true)},[_c('span',[_vm._v("Download Report")])]),_c('v-tooltip',{attrs:{"top":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn reset_btn",attrs:{"icon":"","disabled":candidate.interview_status === _vm.Interview.Status.INVITE_SENT},on:{"click":function($event){return _vm.delete_or_reset_candidate_report(candidate, 'reset')}}},'v-btn',attrs,false),on),[_c('inline-svg',{attrs:{"width":"21","height":"21","src":_vm.retry_icon,"transformSource":function (icon) { return _vm.transform_icon(icon, '#ffb815'); }}})],1)]}}],null,true)},[_c('span',[_vm._v("Reset Interview")])]),_c('v-tooltip',{attrs:{"top":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn delete_btn",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.delete_or_reset_candidate_report(candidate, 'delete')}}},'v-btn',attrs,false),on),[_c('inline-svg',{attrs:{"width":"21","height":"21","src":_vm.delete_icon,"transformSource":function (icon) { return _vm.transform_icon(icon, '#ff3333'); }}})],1)]}}],null,true)},[_c('span',[_vm._v("Delete Interview")])])],1)])])}),0),_c('v-dialog',{attrs:{"max-width":"700px","transition":"dialog-top-transition"},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('DeleteItem',{attrs:{"loading":_vm.loading,"title":_vm.get_action_title,"submit_btn_text":_vm.get_button_text,"icon":_vm.action === 'delete' ? _vm.delete_icon : _vm.retry_icon,"transform_source_color":_vm.action === 'delete' ? '#ff3333' : ' '},on:{"cancel":_vm.dialog_action,"submit":_vm.dialog_action}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }